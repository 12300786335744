<!-- =========================================================================================
  File Name: DataListThumbView.vue
  Description: Data List - Thumb View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-thumb-view" class="data-list-container">
    <data-view-sidebar :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData" />
    <data-view-sharing-popup
      :isSharingPopupActive="addNewDataSharingPopup"
      @closeSharingPopup="toggleDataSharingPopup"
      :data="sharingPopupData"
    />
    <vs-row vs-align="flex-end" vs-type="flex" vs-justify="space-between" vs-w="12">
      <vs-col vs-type="flex" vs-w="6">
        <div
          class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary"
          @click="addNewData"
        >
          <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
          <span class="ml-2 text-base text-primary">Add New</span>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-end" vs-w="6">
        <vs-button class="p-3 mb-4 inline-flex" v-on:click="OpenGallery" color="primary" type="flat">
          <feather-icon icon="LinkIcon" svgClasses="h-4 w-4" />
          {{ this.galleryLink }}
        </vs-button>
        <vs-button v-clipboard:copy="galleryLink" v-clipboard:success="onCopy" class="p-3 mb-4" type="flat">
          <feather-icon icon="CopyIcon" svgClasses="h-4 w-4" />
        </vs-button>
      </vs-col>
    </vs-row>

    <div v-if="showGreetings" id="greet">
      <div class="vx-row">
        <!-- CARD 1: CONGRATS -->
        <div class="vx-col w-full mb-base">
          <vx-card slot="no-body" class="text-center bg-primary-gradient greet-user">
            <img src="@/assets/images/elements/decore-left.png" class="decore-left" alt="Decore Left" width="200" />
            <img src="@/assets/images/elements/decore-right.png" class="decore-right" alt="Decore Right" width="175" />
            <feather-icon
              icon="AwardIcon"
              class="p-6 mb-8 bg-primary inline-flex rounded-full text-white shadow"
              svgClasses="h-8 w-8"
            ></feather-icon>
            <h1 class="mb-6 text-white">Congrats and Welcome to Hoverlay !</h1>
            <p class="xl:w-3/4 lg:w-4/5 md:w-2/3 w-4/5 mx-auto text-white">
              You signed up for Hoverlay and that’s worth celebrating. You’re on your way to removing one of the biggest
              friction point for art buyers: letting them preview your artwork on their own wall.
            </p>
          </vx-card>
        </div>
      </div>

      <!-- <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <iframe
            class="ql-video ql-align-center"
            src="https://www.youtube.com/embed/QHH3iSeDBLo?showinfo=0"
            width="800"
            height="500"
          ></iframe>
        </vs-col>
      </vs-row> -->
    </div>

    <div id="demo-basic-card">
      <div class="vx-row">
        <div v-for="item in products" :key="item.name" class="vx-col w-full sm:w-1/5 lg:w-1/5 mb-base">
          <vx-card>
            <div slot="no-body">
              <img :src="item.original_image" alt="content-img" class="responsive card-img-top" />
            </div>
            <h5 class="mb-2">{{ item.name }}</h5>
            <p class="text-grey">{{ (item.height * 100) | round }} cm ({{ (item.height * 39.3701) | round }} inches)</p>

            <div class="flex justify-between flex-wrap">
              <vs-button class="mt-2" @click.stop="shareData(item)" color="primary" type="line" icon="info"></vs-button>
              <vs-button class="mt-2" @click.stop="editData(item)" color="warning" type="line" icon="edit"></vs-button>
              <vs-button
                class="mt-2"
                @click.stop="deleteData(item.pid)"
                color="danger"
                type="line"
                icon="delete"
              ></vs-button>

              <!-- 
              <vs-button
                class="mt-2"
                type="border"
                @click.stop="editData(item)"
                color="#b9b9b9"
              >Edit</vs-button>
              <vs-button
                class="mt-2"
                type="border"
                @click.stop="deleteData(item.pid)"
                color="#ff8d62"
              >Delete</vs-button> -->
            </div>
          </vx-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataViewSidebar from '../DataViewSidebar.vue'
import DataViewSharingPopup from '../DataViewSharingPopup.vue'

import moduleDataList from '@/store/data-list/moduleDataList.js'

export default {
  components: {
    DataViewSidebar,
    DataViewSharingPopup,
  },
  data() {
    return {
      galleryLink: `https://studio.hoverlay.com/public/${this.$store.state.AppActiveUser.username}`,
      card_1: '',
      selected: '',
      // products: [],
      itemsPerPage: 4,
      isMounted: false,
      addNewDataSidebar: false,
      addNewDataSharingPopup: false,
      sidebarData: {},
      sharingPopupData: {},
      isSharingPopupActive: false,
      showGreetings: false,
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    products() {
      return this.$store.state.dataList.products
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.products.length
    },
  },
  methods: {
    OpenGallery() {
      this.$router.push({ path: `/public/${this.$store.state.AppActiveUser.username}` })
    },
    onCopy() {
      this.$vs.notify({
        title: 'Success',
        text: 'Gallery Link copied successfully',
        color: 'success',
        iconPack: 'feather',
        position: 'top-center',
        icon: 'icon-check-circle',
      })
    },
    handleSelected(tr) {
      this.shareData(tr)
    },

    addNewData() {
      this.$router.push({ name: 'create-basic-wallart' })
      // this.sidebarData = {}
      // this.toggleDataSidebar(true)
    },
    deleteData(pid) {
      console.log(`dataList/removeHobject pid ${pid}`)
      this.$store.dispatch('dataList/removeHobject', pid).catch(err => {
        console.error(err)
      })
    },
    editData(data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.sidebarData = data
      this.toggleDataSidebar(true)
    },
    shareData(data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.sharingPopupData = data
      this.toggleDataSharingPopup(true)
    },
    getOrderStatusColor(status) {
      if (status === 'on_hold') return 'warning'
      if (status === 'delivered') return 'success'
      if (status === 'canceled') return 'danger'
      return 'primary'
    },
    getPopularityColor(num) {
      if (num > 90) return 'success'
      if (num > 70) return 'primary'
      if (num >= 50) return 'warning'
      if (num < 50) return 'danger'
      return 'primary'
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
    toggleDataSharingPopup(val = false) {
      this.addNewDataSharingPopup = val
      console.log(`this.addNewDataSharingPopup = ${val}`)
    },
  },
  created() {
    this.$http
      .get('/api/users/pixinvent/product/1')
      .then(response => {
        this.card_1 = response.data
      })
      .catch(error => {
        console.log(error)
      })

    if (!moduleDataList.isRegistered) {
      this.$store.registerModule('dataList', moduleDataList)
      moduleDataList.isRegistered = true
    }
    this.$store
      .dispatch('dataList/getLayerContent')
      .then(response => {
        if (response.data.hobjects.length == 0) this.showGreetings = true
      })
      .catch(err => {
        this.showGreetings = true
        console.log(err)
      })
  },
  mounted() {
    this.isMounted = true
  },
}
</script>

<style lang="scss">
#greet {
  .greet-user {
    position: relative;

    .decore-left {
      position: absolute;
      left: 0;
      top: 0;
    }
    .decore-right {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media (max-width: 576px) {
    .decore-left,
    .decore-right {
      width: 140px;
    }
  }
}
#data-list-thumb-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 10px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
          &.img-container {
            // width: 1rem;
            // background: #fff;

            span {
              display: flex;
              justify-content: flex-start;
            }

            .product-img {
              height: 110px;
            }
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>

<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <vs-popup class="holamundo"  title="Share this link with others" :active.sync="isSharingPopupActiveLocal">


<div class="px-2">
  <div class="flex -mx-2">
    <div class="w-3/4 px-2">
      <div class="bg-grid-color-secondary h-12">
              <vs-input class="w-full"   v-model="link" />
      </div>
    </div>
    <div class="w-1/4 px-2">
      <div class="bg-grid-color h-12">
         <vs-button
        v-clipboard:copy="link"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError">
        Copy
    </vs-button>
      </div>
    </div>

  </div>
          Here is the link to access your digital twin 

  <!-- Wide center column -->
<!-- <div class="flex">
  <div class="w-full h-12">
    This link is accessible by everyone
  </div>
</div> -->
</div>


    </vs-popup>
</template>

<script>

export default {
  props: {
    isSharingPopupActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  data () {   
    const channelName = JSON.parse(localStorage.getItem('layerInfo')).name

    return {
      channelName,
      link: null,
      pid: null,
      name: '',
      height :'',
      abilities: '',
      dataCategory: null,
      dataImg: null,
      dataOrder_status: 'pending',
      dataPrice: 0
    }
  },
  watch: {
    isSharingPopupActive (val) {
      if (!val) return
      if (Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      } else {
        console.log('hehehe')
        const { category, abilities, pid, img, name, order_status, price } = JSON.parse(JSON.stringify(this.data))
        console.log(pid)
        let height = ''
        try {
          height = JSON.parse(abilities).skin.height
        } catch (error) {
          console.log(error)
          height = 1
        }
        this.link = `https://hoverlay.io/${this.channelName}/pid/${pid}`
        this.pid = pid
        this.name = name
        this.height = height
        this.abilities = JSON.parse(abilities)
        this.dataCategory = category
        this.dataImg = img
        this.dataOrder_status = order_status
        this.dataPrice = price

        //this.initValues()
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.pid, this.name, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    }
  },
  computed: {
    isSharingPopupActiveLocal: {
      get () {
        return this.isSharingPopupActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSharingPopup')
          // this.$validator.reset()
          // this.initValues()
        }
      }
    }
  },
  methods: {
    initValues () {
      if (this.data.id) return
      this.pid = null
      this.name = ''
      this.dataCategory = null
      this.dataOrder_status = 'pending'
      this.dataPrice = 0
      this.dataImg = null
    },
    onCopy () {
      this.$vs.notify({
        title: 'Success',
        text: 'Text copied successfully',
        color: 'success',
        iconPack: 'feather',
        position: 'top-center',
        icon: 'icon-check-circle'
      })
    },
    onError () {
      this.$vs.notify({
        title: 'Failed',
        text: 'Error in copying text',
        color: 'danger',
        iconPack: 'feather',
        position: 'top-center',
        icon: 'icon-alert-circle'
      })
    }
  }
}
</script>